.breadcrumbSec{
    background-color:  #029cfe;
    padding: 10px 0;
}
.breadcrumbSec .breadcrumb{
    margin: 0;
    padding: 0;
}
.breadcrumb-item a {
    color: #ffffff;
}